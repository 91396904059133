<template>
  <div class="bg-white p-sm-32 rounded">
    <h1>Reservasi Donor</h1>
    <div class="text-center mx-auto mt-5">
      <h2>Tentukan Jadwal Donor</h2>
      <p>
        Silahkan pilih tanggal dan jam yang sesuai dengan kenyamanan Anda serta
        ketersediaan waktu di pusat donor darah kami.
      </p>
    </div>
    <form class="mt-5 w-50 mx-auto pb-5" @submit.prevent="onSubmit">
      <b-form-group id="input-group-1" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="formData.tanggal"
          class="mb-3"
          type="date"
          placeholder="Pilih Tanggal"
          :state="
            v$.formData.tanggal.$dirty ? !v$.formData.tanggal.$error : null
          "
          @input="fetchSchedule"
        />
        <b-form-invalid-feedback
          :state="
            v$.formData.tanggal.$dirty ? !v$.formData.tanggal.$error : null
          "
        >
          Data harus diisi
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label-for="input-lokasi">
        <b-form-select
          id="input-lokasi"
          v-model="formData.lokasi"
          class="mb-1"
          placeholder="Pilih lokasi"
          :options="options"
          :state="v$.formData.lokasi.$dirty ? !v$.formData.lokasi.$error : null"
        />
        <b-form-invalid-feedback
          :state="v$.formData.lokasi.$dirty ? !v$.formData.lokasi.$error : null"
        >
          Data harus diisi
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        class="w-100 fw-bold"
        size="lg"
        :disabled="isBusy"
        variant="danger-2"
        type="submit"
      >
        Konfirmasi
        <b-spinner v-if="isBusy" class="mx-3" small label="Loading" />
      </b-button>
    </form>

    <!-- alert -->
    <b-alert
      style="
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99;
        margin-right: 10px;
        margin-top: 10px;
      "
      :show="dismissCountDown"
      dismissible
      :variant="alertType"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <h4 class="alert-heading">Selamat, anda berhasil melakukan reservasi</h4>
      <b-progress
        :variant="alertType"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      />
    </b-alert>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormInput,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
  BAlert,
  BSpinner,
} from "bootstrap-vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import manageBloodvanAPI from "../../../../../api/managebloodvan/manageBloodvanAPI";
import manageUserAPI from "../../../../../api/manageuser/manageUserAPI";
import managePendonorAPI from "../../../../../api/managependonor/managePendonorAPI";

export default {
  components: {
    BFormSelect,
    BFormInput,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    BAlert,
    BSpinner,
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      formData: {
        lokasi: null,
        tanggal: null,
      },
      scheduleList: null,
      isHospitalSchedule: 0,
      options: [{ value: null, text: "Pilih lokasi" }],
      alertType: null,
      isBusy: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: true,
      reservationData: null,
    };
  },
  validations: {
    formData: {
      lokasi: { required },
      tanggal: { required },
    },
  },
  methods: {
    getCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    async fetchSchedule() {
      if (!this.formData.tanggal) return;
      try {
        const response = await manageBloodvanAPI.listSchedule({
          date: this.formData.tanggal,
        });
        this.scheduleList = response.data.data;
        // this.isHospitalSchedule = this.scheduleList.filter(schedule => schedule.hospital_id === 1).map(hospital => hospital.hospital_id)

        this.updateOptions();
      } catch (error) {
        console.error("Error fetching schedule:", error);
      }
    },
    updateOptions() {
      this.options = [
        { value: null, text: "Pilih lokasi" },
        ...Object.values(this.scheduleList).map((item) => ({
          value: item.id,
          text: item.location,
        })),
      ];
    },

    async postReservation() {
      this.isBusy = true;
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found!");
        this.isBusy = false;
        return;
      }
      try {
        const payload = {
          user_id: this.$route.params.id,
          date: this.formData.tanggal,
          hour: this.getCurrentTime(),
        };

        const selectedLocationId = parseInt(this.formData.lokasi);

        const selectedLocation = Object.values(this.scheduleList).find(
          (location) => location.id === selectedLocationId
        );

        if (selectedLocation) {
          if (selectedLocation.type === "hospital") {
            payload.hospital_id = selectedLocation.id;
          } else if (selectedLocation.type === "blood_van") {
            payload.blood_van_id = selectedLocation.id;
          }
        }

        console.log("Payload before sending:", payload, selectedLocation);

        const reservationResponse = await manageUserAPI.createReservation(
          payload,
          token
        );
        this.reservationData = reservationResponse.data;
        if (!this.reservationData.success) {
          this.alertType = "danger";
          this.showDismissibleAlert = true;
          this.dismissCountDown = this.dismissSecs;
          return;
        }
        this.alertType = "success";
        this.showDismissibleAlert = true;
        this.dismissCountDown = this.dismissSecs;
        this.$router.push({ name: "manage-user" });
        const dataAnswer = [{ question_id: "1", answer: "YA" }];
        for (let i = 2; i <= 42; i++) {
          dataAnswer.push({ question_id: i.toString(), answer: "TIDAK" });
        }
        await managePendonorAPI.addRegistrationQuestion({
          registration_id: this.reservationData.data.id,
          data_answer: dataAnswer,
          image: null,
        });
      } catch (error) {
        console.error("Error posting reservation:", error);

        this.$bvToast.toast(error.response.data.message, {
          title: "Gagal Menambahkan Reservasi",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.postReservation();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>
